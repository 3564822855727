import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const CorporatePolicies = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/corporatepolicies-banner.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/corporate-policies" className="active">
                      Corporate Policies
                    </Link>
                    <span className="icondsds"></span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <div className="tab-content tab_main">
                    <div className="tab-pane fade show active" id="login1">
                      <div className="main_wrappersd">
                        <div className="row">
                          <div className="col-md-12">
                            <legend>CORPORATE POLICIES</legend>
                          </div>
                        </div>
                        <div className="row com_profile">
                          <div className="col-md-6">
                            <div className="companysection">
                              <h2 className="corporate_heading">
                                Quality Policy (ISO 9001:2015)
                              </h2>
                              <p>
                                The aim of NPCL is to make consistent efforts in
                                improving the quality of its services in
                                distributing electricity in the licensed area.
                                It serves the power needs of the people while
                                ensuring the highest standards of safety,
                                innovating service delivery mechanisms, and
                                reducing costs. The Company strives to
                                understand and exceed the expectations of the
                                consumers, employees, and stakeholders by
                                complying with all the legal and other kinds of
                                requirements.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="companyimg">
                              <img src="assets/img/quality-policy.jpg" />
                            </div>
                          </div>
                        </div>
                        <div className="row com_profile policy_blk">
                          <div className="col-md-6">
                            <div className="companyimg">
                              <img src="assets/img/information-security.jpg" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="companysection">
                              <h2 className="corporate_heading">
                                Information Security Policy (ISO 27001:2013)
                              </h2>
                              <p>
                                NPCL makes sure that its information assets are
                                optimally utilised by its operations and
                                business practices. It takes all the precautions
                                to identify the risks associated with these
                                information assets and keeps the risks under
                                acceptable levels. NPCL ensures complete
                                protection of the confidentiality, integrity,
                                and availability of the information while
                                delivering value to its customers and
                                stakeholders. It makes certain that all the
                                legal and other applicable requirements are
                                satisfactorily met.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row com_profile">
                          <div className="col-md-6">
                            <div className="companysection">
                              <h2 className="corporate_heading">
                                Energy Policy (ISO 50001:2018)
                              </h2>
                              <p>
                                NPCL aims to become an energy-efficient company
                                through the optimum utilisation of energy at all
                                its facilities.
                              </p>
                              <strong className="wrapper-strong-style">
                                The company
                              </strong>
                              <ul className="policy_cont wrapper-ul-style">
                                <li>
                                  <i className="fa fa-angle-right"></i> Works
                                  consistently towards minimising energy
                                  consumption requirements to achieve the energy
                                  objectives and targets
                                </li>
                                <li>
                                  <i className="fa fa-angle-right"></i> Conducts
                                  half-yearly energy reviews and keeps on
                                  updating the energy objectives and targets
                                </li>
                                <li>
                                  <i className="fa fa-angle-right"></i> Promotes
                                  the procuring of energy-efficient products and
                                  services
                                </li>
                                <li>
                                  <i className="fa fa-angle-right"></i> Complies
                                  with the applicable legal and other kinds of
                                  requirements associated with energy
                                  performance
                                </li>
                                <li>
                                  <i className="fa fa-angle-right"></i>
                                  Encourages energy-efficient design activities
                                </li>
                                <li>
                                  <i className="fa fa-angle-right"></i> Spreads
                                  awareness about its Energy Policy
                                </li>
                                <li>
                                  <i className="fa fa-angle-right"></i>
                                  Encourage energy efficient design activities
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="companyimg">
                              <img src="assets/img/energy-policy.jpg" />
                            </div>
                          </div>
                        </div>
                        <div className="row com_profile policy_blk">
                          <div className="col-md-6">
                            <div className="companyimg">
                              <img src="assets/img/Health2.jpg" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="companysection">
                              <h2 className="corporate_heading">
                                Health & Safety Policy (ISO 45001:2018 )
                              </h2>
                              <p>
                                We, at NPCL reaffirm that safety of all our
                                stakeholders is of utmost importance and take
                                precedence in all our business decisions. We are
                                committed to follow safety practices in all our
                                business processes through an integrated safety
                                management approach, which focuses on people,
                                processes, systems, technology and facilities.
                              </p>
                              <h6>
                                <b>We shall strive to:</b>
                                <ul>
                                  <li>
                                    Consider safety in every decision we make
                                    and in every activity we perform
                                  </li>
                                  <li>
                                    Comply and exceed statutory, regulatory and
                                    other applicable requirements
                                  </li>
                                  <li>
                                    Demonstrate commitment of senior leadership
                                    team in providing safe and healthy working
                                    environment to employees and other
                                    stakeholders
                                  </li>
                                  <li>
                                    Continually improve our safety performance
                                    in each area of work by establishing and
                                    reviewing objectives and targets at all
                                    levels
                                  </li>
                                  <li>
                                    Report and investigate root cause of
                                    incidents, ill health, injuries and
                                    implement necessary controls
                                  </li>
                                  <li>
                                    Eliminate hazard, reduce OH&S risks and
                                    improve OH&S management system through
                                    participation and consultation of employees,
                                    contractors and other stakeholders
                                  </li>
                                </ul>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="row com_profile">
                          <div className="col-md-6">
                            <div className="companysection">
                              <div className="cost_pdf">
                                <img src="assets/img/pdf.png" alt="" />
                                <a href="https://iwebapps.noidapower.com:8032/download/NPCL_CSR_Policy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                  1. NPCL CSR policy
                                </a>
                              </div>
                            </div>

                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/Vigil_Mechanism_Policy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                2. Vigil Mechanism Policy
                              </a>
                            </div>
                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/Nomination_Remuneration_Policy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                3. Nomination and Remuneration Policy
                              </a>
                            </div>
                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/Composition_of_CSR_Committee.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                4. Composition of CSR Committee
                              </a>
                            </div>
                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/CSR_Annual_Action_Plan_2021_22.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                5. CSR Annual Action Plan 2021-22
                              </a>
                            </div>
                       
                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/CSR_Annual_Action_Plan_2022_23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                6. CSR Annual Action Plan 2022-23
                              </a>
                            </div>

                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/CSR_Annual_Action_Plan_2023_24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                7. CSR Annual Action Plan 2023-24
                              </a>
                            </div>

                            <div className="cost_pdf clearfix">
                              <img src="assets/img/pdf.png" alt="" />
                              <a href="https://iwebapps.noidapower.com:8032/download/CSR_Annual_Action_Plan_2024_25.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                8. CSR Annual Action Plan 2024-25
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CorporatePolicies;
