      import React, { useState, useEffect } from "react";
      import Announcement from "../components/Announcement";
      import Slider from "react-slick";
      import Header from "../components/Header";
      import FixMenu from "../components/FixMenu";
      import { Link } from "react-router-dom";
      import { Legend } from "recharts";

      const Legal = () => {
        const [show1, setShow1] = useState(false);
        const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

        useEffect(() => {
          setShow1(true);
        }, []);

        const toggleShow1 = () => {
          setShow1(!show1);
          if (show2) {
            setShow2(false);
          }
          else if (show3) {
            setShow3(false);
          }
          else if (show4) {
            setShow4(false);
          }
          else if (show5) {
            setShow5(false);
          }
          else if (show6) {
            setShow6(false);
          }
          else if (show7) {
            setShow7(false);
          }
        };
      
        const toggleShow2 = () => {
          setShow2(!show2);
          if (show1) {
            setShow1(false);
          }
          else if (show3) {
            setShow3(false);
          }
          else if (show4) {
            setShow4(false);
          }
          else if (show5) {
            setShow5(false);
          }
          else if (show6) {
            setShow6(false);
          }
          else if (show7) {
            setShow7(false);
          }
        };
      
        const toggleShow3 = () => {
          setShow3(!show3);
          if (show2) {
            setShow2(false);
          }
          else if (show1) {
            setShow1(false);
          }
          else if (show4) {
            setShow4(false);
          }
          else if (show5) {
            setShow5(false);
          }
          else if (show6) {
            setShow6(false);
          }
          else if (show7) {
            setShow7(false);
          }
        };
      
        const toggleShow4 = () => {
          setShow4(!show4);
          if (show2) {
            setShow2(false);
          }
          else if (show3) {
            setShow3(false);
          }
          else if (show1) {
            setShow1(false);
          }
          else if (show5) {
            setShow5(false);
          }
          else if (show6) {
            setShow6(false);
          }
          else if (show7) {
            setShow7(false);
          }
        };
      
        const toggleShow5 = () => {
          setShow5(!show5);
          if (show2) {
            setShow2(false);
          }
          else if (show3) {
            setShow3(false);
          }
          else if (show4) {
            setShow4(false);
          }
          else if (show1) {
            setShow1(false);
          }
          else if (show6) {
            setShow6(false);
          }
          else if (show7) {
            setShow7(false);
          }
        };

        const toggleShow6 = () => {
          setShow6(!show6);
          if (show2) {
            setShow2(false);
          }
          else if (show3) {
            setShow3(false);
          }
          else if (show4) {
            setShow4(false);
          }
          else if (show1) {
            setShow1(false);
          }
          else if (show5) {
            setShow5(false);
          }
          else if (show7) {
            setShow7(false);
          }
        };

        const toggleShow7 = () => {
          setShow7(!show7);
          if (show2) {
            setShow2(false);
          }
          else if (show3) {
            setShow3(false);
          }
          else if (show4) {
            setShow4(false);
          }
          else if (show1) {
            setShow1(false);
          }
          else if (show5) {
            setShow5(false);
          }
          else if (show6) {
            setShow6(false);
          }
        };

          const settings2 = {
              dots: true,
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 3000,
              arrows: true,
            };
        return (
          <>
              <div id="bodyaddid">
              <Header />
              <FixMenu />
              <section className="main-banner">
                <div
                  className="innerpagebanner"
                  style={{
                    background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
                    backgroundSize: "100% auto",
                  }}
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-sm-10">
                        <h1>Quick Pay</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <Announcement />
              </section>
              <section className="main_sectiods">
                <div className="container">
                  <div className="row justify-content-center padtops">
                    <div className="col-sm-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link >Home </Link>
                          <span className="icondsds">
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                        CGRFs & Ombudsman
                          {/* <Link>                                                                                                                                                               " className="active">
                          CGRFs & Ombudsman
                          </Link> */}
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div>
<ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow1}
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Status of the CGRF at a Glance
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow2}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Status of the Disposal by CGRFS
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow3}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Format of the Complaint
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow4}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        UPERC (CGRF) Regulations,2022
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow5}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        FAQs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow6}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Cause List
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow7}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Notices
                      </Link>
                    </li>
                  </ul>
</div>

{show1 ? (
  <div className="row justify-content-center">
                    <div className="col-sm-12" >
                      <div className="row">
                      <div className="col-sm-12 bg_cols" style={{padding:"20px 40px"}}>
                      <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                      <legend style={{justifyContent:"center"}}>CONSUMER GRIEVANCE REDRESSAL FORUM GREATER NOIDA</legend>
                      <hr/>
                      <p style={{marginBottom:"20px"}}> Three Consumer Grievance Redressal Forums [<strong>CGRFs</strong>], Greater Noida, (U.P.) have been established for the licensed area of Noida Power Company Limited on 29.09.2023 under Section 42(5) of Electricity Act, 2003, read with the UPERC (Consumer Grievance Redressal Forum) Regulations, 2022 [<strong> “CGRF Regulations 2022” </strong>] for redressal of consumer grievances regarding any dispute, other than theft or unauthorized use of electricity.  </p>
                        <p style={{marginBottom:"24px"}}>If a consumer is not satisfied with the resolution of grievances by the Company, he/she can approach the appropriate forum as per the jurisdiction of the CGRF as mentioned below:     </p>

                        
                        <p  style={{marginBottom:"25px",}}><strong><u>Rural Level </u></strong></p>

                      <p style={{marginBottom:"25px"}}><strong><u>Pecuniary Jurisdiction:</u></strong> Dispute related to rural bills and assessments less than Rs. 50 Lakh. </p>
                          <div className="col-sm-12" style={{marginBottom:"25px"}}>
                            <table className="table table-responsive" style={{border:"1px solid #ddd"}}>
                            <tr>
                              <th className="col-sm-3">Chairman</th>
                              <th  className="col-sm-2">Independent Member</th>
                              <th  className="col-sm-2">First Nominated Member</th>
                              <th  className="col-sm-2">Second Nominated Member</th>
                              <th  className="col-sm-3">Third Nominated Member (Prosumer/Consumer)</th>

                            </tr>
                            <tr>
                              <td>Shri Gaurav Sharma <br/> Senior Manager (Operations) <br/> 9990046407</td>
                              <td>Shri Sunil Kumar-Advocate
                              <br/> 9312201269
                                    </td>
                              <td> Shri Sunil Kumar
                              <br/> 9811558341
                                  </td>
                              <td>Shri Arun Mavi 
                              <br/> 9999313034
                                </td>
                              <td>Shri Virender Singh 
                              <br/> 9015151906
                              </td>
                              
                            </tr>
                            </table>
                      
                          </div>



                          <p  style={{marginBottom:"25px",}}><strong><u>Urban Level </u></strong></p>

                          <p style={{marginBottom:"25px"}}><strong><u>Pecuniary Jurisdiction:</u></strong> Dispute related to urban bills and assessments less than Rs. 50 Lakh. </p>
                            <div className="col-sm-12" style={{marginBottom:"25px"}}>
                              <table className="table table-responsive" style={{border:"1px solid #ddd"}}>
                              <tr>
                                <th className="col-sm-3">Chairman</th>
                                <th  className="col-sm-2">Independent Member</th>
                                <th  className="col-sm-2">First Nominated Member</th>
                                <th  className="col-sm-2">Second Nominated Member</th>
                                <th  className="col-sm-3">Third Nominated Member (Prosumer/Consumer)</th>

                              </tr>
                              <tr>
                                <td>Shri Jitender Dhamat <br/> Senior Manager (Operations) <br/> 8743009894</td>
                                <td> Smt. Veenita Marathia-Advocate <br/> 8920827680 </td>
                                <td> Shri Mulendra Kumar Sharma <br/> 9910562277</td>
                                <td> Shri Satya Prakash Sharma<br/> 9971991484</td>
                                <td>Shri Prem Kumar <br/> 9911927552</td>

                              </tr>
                              </table>

                            </div>



                <p  style={{marginBottom:"25px",}}><strong><u>Company Level </u></strong></p>

                  <p style={{marginBottom:"25px"}}><strong><u>Pecuniary Jurisdiction:</u></strong> Dispute related to bills and assessments equal to and greater than Rs. 50 Lakh. </p>
                    <div className="col-sm-12" style={{marginBottom:"25px"}}>
                      <table className="table table-responsive" style={{border:"1px solid #ddd"}}>
                      <tr>
                        <th className="col-sm-3">Chairman</th>
                        <th  className="col-sm-2">Independent Member</th>
                        <th  className="col-sm-2">First Nominated Member</th>
                        <th  className="col-sm-2">Second Nominated Member</th>
                        <th  className="col-sm-3">Third Nominated Member (Prosumer/Consumer)</th>

                      </tr>
                      <tr>
                        <td>Shri Sanjiv Goel <br/> General Manager (Regulatory)<br/> 9999448102</td>
                        <td> Shri D.S Pandey <br/> 9415716953</td>
                        <td> Shri Devi Ram <br/> 9810366976</td>
                        <td> Shri A.D. Pandey  <br/> 8130393941</td>
                        <td> Shri Omveer Singh  <br/> 8199978958</td>

                      </tr>
                      </table>

                    </div>
                        <div style={{marginBottom:"15px"}}> <strong>Address:</strong> <br />

                         Consumer Grievance Redressal Forum,  Greater Noida  <br/> [CGRF – Rural], [CGRF – Urban] and [CGRF – Company] ,<br/> 33/11 kV Substation, Sector Alpha - I, <br/> Sector Alpha-I, Opposite Ryan International School, <br/> Greater Noida, U.P. – 201310 <br/><br/><strong>Email: cgrf@noidapower.com</strong> <br/><strong>Telephone No: </strong>  &nbsp;&nbsp;&nbsp;&nbsp; 0120-6226644<br/><strong>Website:</strong> &nbsp;&nbsp; www.noidapower.com <br/><strong>Office Hours: </strong> 10.00 AM to 5.00 PM (Monday to Friday)  <br/></div>
                        <p style={{marginBottom:"18px"}}>The forums will address all complaints related to supply and billing, <strong><span className="itlc">excluding</span></strong> the cases relating to pilferage/theft/unauthorised use of electricity. The Consumers, who are not satisfied with decision of the Forum, may file an Appeal before <strong>the Company Level</strong> or the <strong>Hon’ble Ombudsman</strong> as prescribed in the Regulations 2022.</p>
                        <div className="col-sm-12">
                          <div className="row">
                              <div className="col-sm-6">

                              <div> <strong>Electricity Ombudsman</strong><br/>Vidyut Niyamak Bhawan<br/>Vibhuti Khand, Gomti Nagar<br/>Lucknow-226010<br/>Telephone No:91-522-2720856<br/>Fax : +91-522-2720857<br/>Email: eo-up@uperc.org</div>
                              </div>
                              <div className="col-sm-6">

                              <div> <strong>Company Level</strong>   <br/> <strong>Address: </strong>   Consumer Grievance Redressal <br/> Forum, Greater Noida <br/>33/11 kV Substation, D-Block, <br/> Sector Alpha-I, Opposite Ryan International  <br/> School, Greater Noida, U.P. – 201310 <br/><strong>Telephone:</strong>    0120-6226644 <br/> <strong>Email: </strong>cgrf@noidapower.com</div>
                              </div>
                        </div>
                      </div>
                      
                      </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>  
) : null}

{show2 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>FY 2023-24</p>
    <div>  
    <p  style={{marginBottom:"15px", fontWeight: 'bold'}}> Q3 - <a href="https://iwebapps.noidapower.com:8032/download/Legal/Report_Q3.PDF" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <p  style={{marginBottom:"15px", fontWeight: 'bold'}}> Q4 -<a href="https://iwebapps.noidapower.com:8032/download/Legal/Report_Q4.PDF" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
      </div>
      </div>
      <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>FY 2024-25</p>
    <div>  
    <p  style={{marginBottom:"15px", fontWeight: 'bold'}}> Q1 - <a href="https://iwebapps.noidapower.com:8032/download/Legal/Q1 FY 2024-25.PDF" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
      </div>
      </div>
      </div>
      </div>
) : null}

{show3 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>Suggested Format for Filling the Complaint before CGRFs ..................................... <a href="https://iwebapps.noidapower.com:8032/download/Legal/Annexure TAB-3.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
      </div>
      </div>
      </div>
) : null}

{show4 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF Regulations 2022 and NPCL's approval are provided in PDF in the below link:  </p>
    <p  style={{marginBottom:"15px"}}> <a href="https://iwebapps.noidapower.com:8032/download/UPERC CGRF Regulations and NPCL's Approval.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a> – CGRF Regulation 2022, Composition & Jurisdiction</p>
      </div>
      </div>
      </div>
) : null}

{show5 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>FAQs regarding CGRFs .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Annexure TAB-5.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
      </div>
      </div>
      </div>
) : null}

{show6 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (07/06/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Annexure TAB-6.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- URBAN LEVEL (14/06/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Cause List.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (18/06/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Cause List 18.06.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- URBAN LEVEL (05/07/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Cause list 05.07.2024 (Urban).pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (04/07/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Cause list 04.07.2024 (Rural).pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- URBAN LEVEL (19/07/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/UrbanLevel-19.07.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (01/08/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Cause list 01.08.2024 -Rural Level.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- URBAN LEVEL (07/08/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Cause List 07.08.2024-Rural Level.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (13/08/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF-Rural-13.08.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (22/08/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF-RURAL-22.08.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- URBAN LEVEL (23/08/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF-URBAN-23.08.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (30/08/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF-RURAL-30.08.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- COMPANY LEVEL (03/09/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF-Company Level-03.09.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- RURAL LEVEL (12/09/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF Cause List 12.09.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- URBAN LEVEL (13/09/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF CAUSE LIST 13.09.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
    <br></br>
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>CGRF- COMPANY LEVEL (17/09/2024) .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/CGRF-Company Level-17.09.2024.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
      </div>
      </div>
      </div>
) : null}

{show7 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <p  style={{marginBottom:"25px", fontWeight: 'bold'}}>Advertisement for the Members of CGRFs .............................................. <a href="https://iwebapps.noidapower.com:8032/download/Legal/Advertisements.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"100px" }} alt="NPCL"/></a></p>
      </div>
      </div>
      </div>
) : null}

             {/* <div className="row justify-content-center">
                    <div className="col-sm-12" >
                      <div className="row">
                      <div className="col-sm-12 bg_cols" style={{padding:"20px 40px"}}>
                      <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                      <legend style={{justifyContent:"center"}}>CONSUMER GRIEVANCE REDRESSAL FORUM GREATER NOIDA</legend>
                      <hr/>
                      <p style={{marginBottom:"20px"}}> Three Consumer Grievance Redressal Forums [<strong>CGRFs</strong>], Greater Noida, (U.P.) have been established for the licensed area of Noida Power Company Limited on 29.09.2023 under Section 42(5) of Electricity Act, 2003, read with the UPERC (Consumer Grievance Redressal Forum) Regulations, 2022 [<strong> “CGRF Regulations 2022” </strong>] for redressal of consumer grievances regarding any dispute, other than theft or unauthorized use of electricity.  </p>
                        <p style={{marginBottom:"24px"}}>If a consumer is not satisfied with the resolution of grievances by the Company, he/she can approach the appropriate forum as per the jurisdiction of the CGRF as mentioned below:     </p>

                        
                        <p  style={{marginBottom:"25px",}}><strong><u>Pecuniary Rural Level </u></strong></p>

                      <p style={{marginBottom:"25px"}}><strong><u>Jurisdiction:</u></strong> Dispute related to rural bills and assessments less than Rs. 50 Lakh. </p>
                          <div className="col-sm-12" style={{marginBottom:"25px"}}>
                            <table className="table table-responsive" style={{border:"1px solid #ddd"}}>
                            <tr>
                              <th className="col-sm-3">Chairman</th>
                              <th  className="col-sm-2">First Nominated Member</th>
                              <th  className="col-sm-2">Second Nominated Member</th>
                              <th  className="col-sm-3">Third Nominated Member (Prosumer/Consumer)</th>
                              <th  className="col-sm-2">Independent Member</th>
                            </tr>
                            <tr>
                              <td>Shri Gaurav Sharma <br/> Senior Manager (Operations)</td>
                              <td> Shri Sunil Kumar

                                  </td>
                              <td>Shri Arun Mavi 

                                </td>
                              <td>Shri Virender Singh 

                              </td>
                              <td>Shri Sunil Kumar-Advocate

                                    </td>
                            </tr>
                            </table>
                      
                          </div>



                          <p  style={{marginBottom:"25px",}}><strong><u>Urban Level </u></strong></p>

                          <p style={{marginBottom:"25px"}}><strong><u>Pecuniary Jurisdiction:</u></strong> Dispute related to urban bills and assessments less than Rs. 50 Lakh. </p>
                            <div className="col-sm-12" style={{marginBottom:"25px"}}>
                              <table className="table table-responsive" style={{border:"1px solid #ddd"}}>
                              <tr>
                                <th className="col-sm-3">Chairman</th>
                                <th  className="col-sm-2">First Nominated Member</th>
                                <th  className="col-sm-2">Second Nominated Member</th>
                                <th  className="col-sm-3">Third Nominated Member (Prosumer/Consumer)</th>
                                <th  className="col-sm-2">Independent Member</th>
                              </tr>
                              <tr>
                                <td>Shri Jitender Dhamat <br/> Senior Manager (Operations) </td>
                                <td> Shri Mulendra Kumar Sharma </td>
                                <td> Shri Satya Prakash Sharma</td>
                                <td>Shri Prem Kumar </td>
                                <td> Smt. Veenita Marathia-Advocate  </td>
                              </tr>
                              </table>

                            </div>



                <p  style={{marginBottom:"25px",}}><strong><u>Company Level </u></strong></p>

                  <p style={{marginBottom:"25px"}}><strong><u>Pecuniary Jurisdiction:</u></strong> Dispute related to bills and assessments equal to and greater than Rs. 50 Lakh. </p>
                    <div className="col-sm-12" style={{marginBottom:"25px"}}>
                      <table className="table table-responsive" style={{border:"1px solid #ddd"}}>
                      <tr>
                        <th className="col-sm-3">Chairman</th>
                        <th  className="col-sm-2">First Nominated Member</th>
                        <th  className="col-sm-2">Second Nominated Member</th>
                        <th  className="col-sm-3">Third Nominated Member (Prosumer/Consumer)</th>
                        <th  className="col-sm-2">Independent Member</th>
                      </tr>
                      <tr>
                        <td>Shri Sanjiv Goel <br/> General Manager (Regulatory)</td>
                        <td> Shri Devi Ram </td>
                        <td> Shri A.D. Pandey  </td>
                        <td> Shri Omveer Singh  </td>
                        <td> - </td>
                      </tr>
                      </table>

                    </div>
                          
                        <p  style={{marginBottom:"25px"}}>Nature of Dispute, Pecuniary Jurisdiction of Forums and CGRF Regulations 2022 are provided in PDF in the below link:  </p>
                        <p  style={{marginBottom:"15px"}}> <a href="https://iwebapps.noidapower.com:8032/download/UPERC CGRF Regulations and NPCL's Approval.pdf" target="_blank" rel="noreferrer"><img src="assets/img/pdf.png" style={{ height:"50px" }} alt="NPCL"/></a> – CGRF Regulation 2022, Composition & Jurisdiction</p>

                        <div style={{marginBottom:"15px"}}> <strong>Address:</strong> <br />

                         Consumer Grievance Redressal Forum,  Greater Noida  <br/> [CGRF – Rural], [CGRF – Urban] and [CGRF – Company] ,<br/> 33/11 kV Substation, Sector Alpha - I, <br/> Sector Alpha-I, Opposite Ryan International School, <br/> Greater Noida, U.P. – 201310 <br/><br/><strong>Email: cgrf@noidapower.com</strong> <br/><strong>Telephone No: </strong>  &nbsp;&nbsp;&nbsp;&nbsp; 0120-6226644<br/><strong>Website:</strong> &nbsp;&nbsp; www.noidapower.com <br/><strong>Office Hours: </strong> 10.00 AM to 5.00 PM (Monday to Friday)  <br/></div>
                        <p style={{marginBottom:"18px"}}>The forums will address all complaints related to supply and billing, <strong><span className="itlc">excluding</span></strong> the cases relating to pilferage/theft/unauthorised use of electricity. The Consumers, who are not satisfied with decision of the Forum, may file an Appeal before <strong>the Company Level</strong> or the <strong>Hon’ble Ombudsman</strong> as prescribed in the Regulations 2022.</p>
                        <div className="col-sm-12">
                          <div className="row">
                              <div className="col-sm-6">

                              <div> <strong>Electricity Ombudsman</strong><br/>Vidyut Niyamak Bhawan<br/>Vibhuti Khand, Gomti Nagar<br/>Lucknow-226010<br/>Telephone No:91-522-2720856<br/>Fax : +91-522-2720857<br/>Email: eo-up@uperc.org</div>
                              </div>
                              <div className="col-sm-6">

                              <div> <strong>Company Level</strong>   <br/> <strong>Address: </strong>   Consumer Grievance Redressal <br/> Forum, Greater Noida <br/>33/11 kV Substation, D-Block, <br/> Sector Alpha-I, Opposite Ryan International  <br/> School, Greater Noida, U.P. – 201310 <br/><strong>Telephone:</strong>    0120-6226644 <br/> <strong>Email: </strong>cgrf@noidapower.com</div>
                              </div>
                        </div>
                      </div>
                      
                      </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>    */}
 
                </div>
              </section>
              </div>
          </>
        )
      }

      export default Legal