      import React, { useState, useEffect } from "react";
      import Announcement from "../components/Announcement";
      import Slider from "react-slick";
      import Header from "../components/Header";
      import FixMenu from "../components/FixMenu";
      import { Link } from "react-router-dom";
      import { Legend } from "recharts";


      const TariffPetetionARR2425 = () => {
        const [show1, setShow1] = useState(false);
        const [show2, setShow2] = useState(false);
        const [show3, setShow3] = useState(false);
        const [show4, setShow4] = useState(false);
        const [show5, setShow5] = useState(false);
        const [show6, setShow6] = useState(false);
        const [show7, setShow7] = useState(false);
        const [show8, setShow8] = useState(false);
        const [show9, setShow9] = useState(false);

        useEffect(() => {
            setShow1(true);
          }, []);

          const toggleShow1 = () => {
            setShow1(!show1);
            if (show2) {
              setShow2(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };
        
          const toggleShow2 = () => {
            setShow2(!show2);
            if (show1) {
              setShow1(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };
        
          const toggleShow3 = () => {
            setShow3(!show3);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };

          const toggleShow4 = () => {
            setShow4(!show4);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };

          const toggleShow5 = () => {
            setShow5(!show5);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };

          const toggleShow6 = () => {
            setShow6(!show6);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };

          const toggleShow7 = () => {
            setShow7(!show7);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };

          const toggleShow8 = () => {
            setShow8(!show8);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show7) {
              setShow7(false);
            }
            else if (show9) {
              setShow9(false);
            }
          };

          const toggleShow9 = () => {
            setShow9(!show9);
            if (show2) {
              setShow2(false);
            }
            else if (show1) {
              setShow1(false);
            }
            else if (show4) {
              setShow4(false);
            }
            else if (show5) {
              setShow5(false);
            }
            else if (show3) {
              setShow3(false);
            }
            else if (show6) {
              setShow6(false);
            }
            else if (show8) {
              setShow8(false);
            }
            else if (show7) {
              setShow7(false);
            }
          };

          const settings2 = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: true,
          };

          return (
            <>
<div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/NPCL-Tariff-Petition-ARR-2024-25" className="active">
                    NPCL Tariff Petition for ARR 2024-25
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login2">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="companysection">
                              <legend> NPCL Tariff Petition for ARR 2024-25 </legend>
                              </div></div></div></div>
                              </div></div></div></div></div>

                              <div>
<ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow1}
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        NPCL Tariff Petition for ARR 2024-25
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow2}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Reply to Deficiency Note -1
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow3}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Reply to Deficiency Note -2
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow4}
                        className="nav-link"
                        data-toggle="tab"
                      >
                         Reply to Deficiency Note -3
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow5}
                        className="nav-link"
                        data-toggle="tab"
                      >
                         TVS MoM Reply
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow6}
                        className="nav-link"
                        data-toggle="tab"
                      >
                         Reply to Deficiency Note -4
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow7}
                        className="nav-link"
                        data-toggle="tab"
                      >
                         Reply to Deficiency Note -5
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow8}
                        className="nav-link"
                        data-toggle="tab"
                      >
                         Reply to Deficiency Note -6
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow9}
                        className="nav-link"
                        data-toggle="tab"
                      >
                         Reply to Deficiency Note -7
                      </Link>
                    </li>
                  </ul>
</div>

{show1 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Coverpage.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                1. Coverpage
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Cover Letter (Signed).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                2. Cover Letter (Signed)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Petition Fee ARR 2024-25.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                2b. Petition Fee ARR 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Index Page.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                3. Index Page
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Form 1 ARR FY 2024-25.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Form 1 ARR FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Form-2 Affidavit Tariff Petition ARR FY24-25.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                5. Form-2 Affidavit Tariff Petition ARR FY24-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-I Power of Attorney.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                6. Appendix-I Power of Attorney
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-II MYT Formats APR and ARR FY 2024-25 ( Linked).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                7. Appendix-II MYT Formats APR and ARR FY 2024-25 ( Linked)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-III Text of True-up FY 2022-23.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                8. Appendix-III Text of True-up FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-IV Text of APR FY 2023-24.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                9. Appendix-IV Text of APR FY 2023-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-V Text of ARR FY 2024-25.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                10. Appendix-V Text of ARR FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-VI Cost Allocation.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                               11. Appendix-VI Cost Allocation
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-VII Compliance Hon'ble Commission's Directives.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                12. Appendix-VII Compliance Hon'ble Commission's Directives
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-VIII Additional MYT Format-I.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                13. Appendix-VIII Additional MYT Format-I
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-IX Additional MYT Format- II.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                14. Appendix-IX Additional MYT Format- II
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-X (a) Draft Public Notice (English).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                15. Appendix-X (a) Draft Public Notice (English)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-X (b) Draft Public Notice (Hindi).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                16. Appendix-X (b) Draft Public Notice (Hindi)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix-XI Executive Summary.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                17. Appendix-XI Executive Summary
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Appendix- XII Soft copy of Petition.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                18. Appendix- XII Soft copy of Petition
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-1(a) NPCL Audited Accounts FY 2022-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              19. Annexure-1(a) NPCL Audited Accounts FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-1(b) NPCL Cost Audit Report FY 2022-23.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                20. Annexure-1(b) NPCL Cost Audit Report FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure- 2 Order dt 06.05.20 Addnl Coal FY-19 Petition No. 1438 of 2019.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               21. Annexure- 2 Order dt 06.05.20 Addnl Coal FY-19 Petition No. 1438 of 2019
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-3 Order dt 29.05.20 Change in Law FY 19 Petition No. - 1440 of 2019.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                22. Annexure-3 Order dt 29.05.20 Change in Law FY 19 Petition No. - 1440 of 2019
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-4 Order dt. 02.08.22 for CG Environment Cess and Development Cess.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                23. Annexure-4 Order dt. 02.08.22 for CG Environment Cess and Development Cess
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-5 Order dt. 08.06.2022 for Addnl Coal FY 19-20 Petition No. 1630 of 2020.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                               24. Annexure-5 Order dt. 08.06.2022 for Addnl Coal FY 19-20 Petition No. 1630 of 2020
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annxure-6 Order dated 20.03.2023 Addnl Coal FY-21 Petition No. 1794 of 2021.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                25. Annxure-6 Order dated 20.03.2023 Addnl Coal FY-21 Petition No. 1794 of 2021
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-7 Order dt.13.06.2023 Addnl Coal FY-22 Petition no. 1873 of 2022.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                26. Annexure-7 Order dt.13.06.2023 Addnl Coal FY-22 Petition no. 1873 of 2022
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-8  Order dt 28.06.2022 Change in Law FY-20 Petition No. 1654 of 2020.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                27. Annexure-8  Order dt 28.06.2022 Change in Law FY-20 Petition No. 1654 of 2020
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-9 Order dt 12.05.2023 Change in Lawa FY-21 Petition No. 1798 of 2021.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                28. Annexure-9 Order dt 12.05.2023 Change in Lawa FY-21 Petition No. 1798 of 2021
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-10 Order dt. 13.06.2023 Change in Law FY-22 Petition No. 1879 of 2022.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                29. Annexure-10 Order dt. 13.06.2023 Change in Law FY-22 Petition No. 1879 of 2022
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-11 Copies of Approved Orders, PPA.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                30. Annexure-11 Copies of Approved Orders, PPA
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-12 Copy of Letters to UPERC dated 27.10.16 and 17.03.17.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                31. Annexure-12 Copy of Letters to UPERC dated 27.10.16 and 17.03.17
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-13 Renewable Power Obligation (RPO) Status.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                32. Annexure-13 Renewable Power Obligation (RPO) Status
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-10 Order dt. 13.06.2023 Change in Law FY-22 Petition No. 1879 of 2022.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                33. Annexure-14 Capital Expenditure Project Wise FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-15 Fixed Asset Register FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                34. Annexure-15 Fixed Asset Register FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-16 Assets Retirement Details FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                35. Annexure-16 Assets Retirement Details FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-17 Depreciation as per UPERC Method.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                36. Annexure-17 Depreciation as per UPERC Method
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-10 Order dt. 13.06.2023 Change in Law FY-22 Petition No. 1879 of 2022.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                37. Annexure-18 Tax Payment details FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-19 Demand Estimate FY 2023-24.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                38. Annexure-19 Demand Estimate FY 2023-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-20(a) Capital Expenditure Plan FY 23-24 (Above Rs. 10 Cr-Approved).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                39. Annexure-20(a) Capital Expenditure Plan FY 23-24 (Above Rs. 10 Cr-Approved)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-20(b) Capital Expenditure Plan FY 23-24 (Below Rs. 10 Cr.).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                40. Annexure-20(b) Capital Expenditure Plan FY 23-24 (Below Rs. 10 Cr.)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-21 Estimation of Consumer Contribution.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                41. Annexure-21 Estimation of Consumer Contribution
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-22 Demand Estimates FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                42. Annexure-22 Demand Estimates FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-23 Monthwise PPC FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                43. Annexure-23 Monthwise PPC FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-24(a) Capital Expenditure Plan (Above Rs. 10 Cr-Approved).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                44. Annexure-24(a) Capital Expenditure Plan (Above Rs. 10 Cr-Approved)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-24(a) Capital Expenditure Plan (Above Rs. 10 Cr-Approved).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                45. Annexure-24(a) Capital Expenditure Plan (Above Rs. 10 Cr-Approved)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-24(b) Capital Expenditure Plan (Above Rs. 10 Cr.-Proposed).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                46. Annexure-24(b) Capital Expenditure Plan (Above Rs. 10 Cr.-Proposed)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-24(c) Petition for CAPEX Approval for Cyber Resilience.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                47. Annexure-24(c) Petition for CAPEX Approval for Cyber Resilience
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-24(d) Capital Expenditure Plan (Less than Rs. 10 Cr.-Proposed).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                48. Annexure-24(d) Capital Expenditure Plan (Less than Rs. 10 Cr.-Proposed)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-25  Copy of the email dated 03.11.2023.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                49. Annexure-25  Copy of the email dated 03.11.2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-26 Data Centre Petition Petition No. 2010of 2023 dated 16.08.2023.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                50. Annexure-26 Data Centre Petition Petition No. 2010of 2023 dated 16.08.2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-27 Order dated 11.10.2023 in Data Centre Petition No. 2010 of 2023.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                               51. Annexure-27 Order dated 11.10.2023 in Data Centre Petition No. 2010 of 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-28 E-Billing Letter to Hon'ble Commission dated 04.10.2023.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                52. Annexure-28 E-Billing Letter to Hon'ble Commission dated 04.10.2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-29 Copy of Letter related Farmers Tubewell Subsidy.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                53. Annexure-29 Copy of Letter related Farmers Tubewell Subsidy
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-30 LifeLine Consumer Revenue details.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                54. Annexure-30 LifeLine Consumer Revenue details
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-31 Energy Audit Report FY-2022-23.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                55. Annexure-31 Energy Audit Report FY-2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-32 List of Open Access Consumers.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                56. Annexure-32 List of Open Access Consumers
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-33 Monthwise Billing Determinants FY 2022-23.xlsb"
                                target="_blank" rel="noreferrer" 
                              >
                                57. Annexure-33 Monthwise Billing Determinants FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-34 Reco of O&M Expense FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                58. Annexure-34 Reco of O&M Expense FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-35 Load Curve FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                59. Annexure-35 Load Curve FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-36 Cross Subsidy Surcharge FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                60. Annexure-36 Cross Subsidy Surcharge FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-37 Details of Net Metering FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                61. Annexure-37 Details of Net Metering FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-38 Monthwise Power Purchase FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                62. Annexure-38 Monthwise Power Purchase FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-39  Last five years Billing determinants.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                63. Annexure-39  Last five years Billing determinants
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-40 Reconcilliation of Power Purchase FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                64. Annexure-40 Reconcilliation of Power Purchase FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-41 Energy Balances FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                65. Annexure-41 Energy Balances FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-42 List of HPO Generators.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                66. Annexure-42 List of HPO Generators
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-43 Evidence of Rate of Interest for CSD.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                67. Annexure-43 Evidence of Rate of Interest for CSD
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-44 (b) Soft copy of Cost Records for FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                68. Annexure-44 (b) Soft copy of Cost Records for FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-44(a) Soft Copy of Audited Annual Financial Statements FY 22-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-44(a) Soft Copy of Audited Annual Financial Statements FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-14.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-14
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-15.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-15
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-16.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-16
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-17.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-17
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-18.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-18
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-19.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-19
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-20.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-20
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-21.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-21
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-22.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-22
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-23.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-24.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-25.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/NPCL Tariff Petition for ARR 2024-25/Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-26.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                69. Annexure-45 Copies of Power Purchase Bills (FY 2022-23) Vol-26
                              </a>
                            </li>
                          </ul>
        </div>
        </div>
        </div>
) : null}

{show2 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Cover Letter.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. Cover Letter
                              </a>
                            </li>
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Affidavit.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               2. Affidavit
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/NPCL Reply to 1st Deficiency - ARR FY 2024-25_Final_Clean with comments.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               NPCL Reply to 1st Deficiency - ARR FY 2024-25_Final_Clean with comments
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-1 SAUBHAGYA Scheme details FY23 - FY25.xlsb"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Annexure-1 SAUBHAGYA Scheme details FY23 - FY25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-2 Form F10A RTF.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                5. Annexure-2 Form F10A RTF
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-3. Energy Audit Report FY2022-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure-3. Energy Audit Report FY2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-4 PPC Justification & Reconciliation Final.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               7. Annexure-4 PPC Justification & Reconciliation Final
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-5 DSM Bills1.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               8. Annexure-5 DSM Bills1
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-5 DSM Bills2.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               9. Annexure-5 DSM Bills2
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-6 GENCO Invoice No. NPCL-MI-22-23-01.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               10. Annexure-6 GENCO Invoice No. NPCL-MI-22-23-01
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-7 Details of Net Metering FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                11. Annexure-7 Details of Net Metering FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-8 Details of Ineterest on Consumer Security Deposit.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                12. Annexure-8 Details of Ineterest on Consumer Security Deposit
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-9 List of Open Access Consumers.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                13. Annexure-9 List of Open Access Consumers
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-10 Consumer Bills.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               14. Annexure-10 Consumer Bills
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-11 Demand Estimate FY 2023-24.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                15. Annexure-11 Demand Estimate FY 2023-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-12 Demand Estimates FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                16. Annexure-12 Demand Estimates FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-13 Reverse Auction Order.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               17. Annexure-13 Reverse Auction Order
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-14 UPSLDC Letter.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               18. Annexure-14 UPSLDC Letter
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-15. NPCL - Petition for CAPEX 1950 of 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               19. Annexure-15. NPCL - Petition for CAPEX 1950 of 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-16. Hon_ble UPERC Order for Petition 1950 of 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               20. Annexure-16. Hon_ble UPERC Order for Petition 1950 of 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-17 NPCL_Project Progress Report for Pt. no. 1950 of 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               21. Annexure-17 NPCL_Project Progress Report for Pt. no. 1950 of 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -1/Annexure-18 Legal Status and Financial Impact.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                22. Annexure-18 Legal Status and Financial Impact
                              </a>
                            </li>

                          </ul>
        </div>
        </div>
        </div>
) : null}

{show3 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Cover Letter DN2.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. Cover Letter DN2
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Affidavit DN2.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               2. Affidavit DN2
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/NPCL Reply to 2nd Deficiency Note 03.05.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               3. NPCL Reply to 2nd Deficiency Note 03.05.2024
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-1  Summary of DIL Provisions &  Reversal.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Annexure-1  Summary of DIL Provisions &  Reversal
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-2 Details of Credit Notes FY 2022-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               5. Annexure-2 Details of Credit Notes FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-3 Summary of Power Procured from Tata Power (PPGCL& JPL).pdf"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure-3 Summary of Power Procured from Tata Power (PPGCL& JPL)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-4 Regional Energy Accounts FY 2022-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               7. Annexure-4 Regional Energy Accounts FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-5 IEX DAM Obligation Report.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               8. Annexure-5 IEX DAM Obligation Report
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-5 IEX RTM Obligation Report.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               9. Annexure-5 IEX RTM Obligation Report
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-5 IEX TAM Obligation Report.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               10. Annexure-5 IEX TAM Obligation Report
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-6 Datewise Power Purchase FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                11. Annexure-6 Datewise Power Purchase FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-7 Reconciliation of Adani Solar.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                12. Annexure-7 Reconciliation of Adani Solar
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-8 Reconciliation of Tata Solar.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                13. Annexure-8 Reconciliation of Tata Solar
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-9 Summary of Power Procured from PTC (Bannari, NSL and Singoli).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                14. Annexure-9 Summary of Power Procured from PTC (Bannari, NSL and Singoli)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-10 Summary of Power Procured from KEIPL.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                15. Annexure-10 Summary of Power Procured from KEIPL
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-11 Monthwise Power Purchase with Point of Injection.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                16. Annexure-11 Monthwise Power Purchase with Point of Injection
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-12 IEX Exchange Obligation (Renewable).pdf"
                                target="_blank" rel="noreferrer"
                              >
                               17.Annexure-12 IEX Exchange Obligation (Renewable)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-13 Details of Material used in RM with make_model.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                18. Annexure-13 Details of Material used in RM with make_model
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-14 Work Order FY 2022-23 with Index.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               19. Annexure-14 Work Order FY 2022-23 with Index
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-15 Capital Expenditure Project Wise FY 22-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                20. Annexure-15 Capital Expenditure Project Wise FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-16 Computer Software FAR details.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                21. Annexure-16 Computer Software FAR details
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-17 Project Wise Details.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               22. Annexure-17 Project Wise Details
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-18 Category Wise estimates for New Connections with Index.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               23. Annexure-18 Category Wise estimates for New Connections with Index
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-19 Fixed Asset Register.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                24. Annexure-19 Fixed Asset Register
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-20 Captive Consumtion Revenue Details.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                25. Annexure-20 Captive Consumtion Revenue Details
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -2/Annexure-21  CWIP Data in Prescribed Format.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                26. Annexure-21  CWIP Data in Prescribed Format
                              </a>
                            </li>
                            
                          </ul>
        </div>
        </div>
        </div>
) : null}

{show4 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/NPCL Reply to 3rd Deficiency Note ARR 2024-25.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. NPCL Reply to 3rd Deficiency Note ARR 2024-25
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-1 Form F10A FY 2022-23 Revised.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               2. Annexure-1 Form F10A FY 2022-23 Revised
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-2 List of Open Access Consumers.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               3. Annexure-2 List of Open Access Consumers
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-3 Demand Estimate FY 2023-24.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Annexure-3 Demand Estimate FY 2023-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-4 Demand Estimates FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               5. Annexure-4 Demand Estimates FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-5 Monthwise Details of LT Power Cost for FY-23.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure-5 Monthwise Details of LT Power Cost for FY-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-6 Order dt 12.04.24 Change in Law FY 23 Petition No.2004 of 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               7. Annexure-6 Order dt 12.04.24 Change in Law FY 23 Petition No.2004 of 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-7 Order dt. 02.05.2024 Additional Coal FY 23 Petition No. 2005 of 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               8. Annexure-7 Order dt. 02.05.2024 Additional Coal FY 23 Petition No. 2005 of 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-8 Details of arrears claimed.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               9. Annexure-8 Details of arrears claimed
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-9 LTPPA Energy Bills FY-20.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               10. Annexure-9 LTPPA Energy Bills FY-20
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-9 LTPPA Energy Bills FY-21.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                11. Annexure-9 LTPPA Energy Bills FY-21
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-10 Extract of Tariff Order dt. 26-Aug-21.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                12. Annexure-10 Extract of Tariff Order dt. 26-Aug-21
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-11 Extract of Tariff Order dt. 20-Jul-22.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                13. Annexure-11 Extract of Tariff Order dt. 20-Jul-22
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-12 Extract of Tariff Order dt. 24-May-23.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                14. Annexure-12 Extract of Tariff Order dt. 24-May-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-13 UPERC Order dated 21.09.2022 for Genco.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                15. Annexure-13 UPERC Order dated 21.09.2022 for Genco
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-14 UPERC Order dated 22.11.2021 for Genco.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                16. Annexure-14 UPERC Order dated 22.11.2021 for Genco
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-15 NPCL True up Order dated 24.05.2023(FY 17-19).pdf"
                                target="_blank" rel="noreferrer"
                              >
                               17.Annexure-15 NPCL True up Order dated 24.05.2023(FY 17-19)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-16 Extract of Tariff Order dt. 20-July-22 for MOTA arrears FY 21.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                18. Annexure-16 Extract of Tariff Order dt. 20-July-22 for MOTA arrears FY 21
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-17 Extract of Tariff Order dt. 24-May-23 for MOTA arrears FY 22.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               19. Annexure-17 Extract of Tariff Order dt. 24-May-23 for MOTA arrears FY 22
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-18 APPCPL_Demand Letter dt. 25.09.2023 (Claimed Rs. 0.35 Cr., Rs. 2.48 Cr, Rs. 0.35 CR & Rs. 2 Cr).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                20. Annexure-18 APPCPL_Demand Letter dt. 25.09.2023 (Claimed Rs. 0.35 Cr., Rs. 2.48 Cr, Rs. 0.35 CR & Rs. 2 Cr)
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-19 Details of Projects.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                21. Annexure-19 Details of Projects
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-20 Capital Expenditure Project Wise FY 22-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               22. Annexure-20 Capital Expenditure Project Wise FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-21 Bifurcation of Estimates -Final.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               23. Annexure-21 Bifurcation of Estimates -Final
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-22 Tax Payment details FY 22-23.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                24. Annexure-22 Tax Payment details FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-23 Capital Expenditure FY 2024-25.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                25. Annexure-23 Capital Expenditure FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -3/Annexure-24 Obligation Report 29.05.2024.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                26. Annexure-24 Obligation Report 29.05.2024
                              </a>
                            </li>                            
                          </ul>
        </div>
        </div>
        </div>
) : null}

{show5 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/NPCL Reply to TVS MoM ARR 2024-25 - 01.07.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. NPCL Reply to TVS MoM ARR 2024-25 - 01.07.2024
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/Annexure-A1 ABR FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               2. Annexure-A1 ABR FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/Annexure-A2 Form F10A FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               3. Annexure-A2 Form F10A FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/Annexure-A3 RE Power LT_ST FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Annexure-A3 RE Power LT_ST FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/Annexure-A4 O&M Expense details.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               5. Annexure-A4 O&M Expense details
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/Annexure-A5 Soft Copy of Audited Annual Financial Statements FY 22-23.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure-A5 Soft Copy of Audited Annual Financial Statements FY 22-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/TVS MoM Reply/Annexure-A6 Registry Document Land.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               7. Annexure-A6 Registry Document Land
                              </a>
                            </li>
                          </ul>
        </div>
        </div>
        </div>
) : null}

{show6 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -4/NPCL Reply to 4th Deficiency - ARR FY 2024-25.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. NPCL Reply to 4th Deficiency - ARR FY 2024-25
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note -4/Annexure-1 Revised Form 40A CSS FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               2. Annexure-1 Revised Form 40A CSS FY 2024-25
                              </a>
                            </li>
                          </ul>
        </div>
        </div>
        </div>
) : null}

{show7 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-5/NPCL reply to 5th Deficiency Note.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. NPCL reply to 5th Deficiency Note
                              </a>
                            </li>
    <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-5/Annexure-1 Load Curve FY23 & FY24.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               2. Annexure-1 Load Curve FY23 & FY24
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-5/Annexure-2 Load Curve FY25.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               3. Annexure-2 Load Curve FY25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-5/Annexure-3 IEX DAM Data FY23 & FY24.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               4. Annexure-3 IEX DAM Data FY23 & FY24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-5/Annexure-4 Proposed ToD Structure.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               5. Annexure-4 Proposed ToD Structure
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-5/Annexure-5 Trial Balance FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure-5 Trial Balance FY 2022-23
                              </a>
                            </li>
                            
                          </ul>
        </div>
        </div>
        </div>
        
) : null}

{show8 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">   
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/NPCL Reply to 6th Deficiency Note.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               NPCL Reply to 6th Deficiency Note
                              </a>
                            </li>
    <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 1_Query 2. Final Assessment Bill & Payment Receipt.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. Annexure 1_Query 2. Final Assessment Bill & Payment Receipt
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 2_Query 4. NIDP Developers Load Sanction.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               2. Annexure 2_Query 4. NIDP Developers Load Sanction
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 3_Query 5 and 6_Contractor wise payment R&M Expenses.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               3. Annexure 3_Query 5 and 6_Contractor wise payment R&M Expenses
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 4_Letter to UPERC dated 01.10.2109 regarding Pilot project Status Report.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               4. Annexure 4_Letter to UPERC dated 01.10.2109 regarding Pilot project Status Report
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 5_Hon'ble Commission order dated 22.07.2020.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               5. Annexure 5_Hon'ble Commission order dated 22.07.2020
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 6_Notice Inviting Tender.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure 6_Notice Inviting Tender
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 6.1_UPERC order dt 24.07.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               6.1. Annexure 6.1_UPERC order dt 24.07.2024
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 6.2_Details of successful Bidder.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               6.2. Annexure 6.2_Details of successful Bidder
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 7_Receivable Write-off and Provision Policy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               7. Annexure 7_Receivable Write-off and Provision Policy
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 8_Security Deposit.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               8. Annexure 8_Security Deposit
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 9_DISTRIBUTION TRANSFORMER REVISION-D CONTROLLED COPY.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               9. Annexure 9_DISTRIBUTION TRANSFORMER REVISION-D CONTROLLED COPY
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 10_TS_12.5MVA_33_11kV_ONAN_Three_Phase_Power_Transformer.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               10. Annexure 10_TS_12.5MVA_33_11kV_ONAN_Three_Phase_Power_Transformer
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 11 1.1 kV 2-Core XLPE Insulated PVC Sheathed Cable.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               11. Annexure 11 1.1 kV 2-Core XLPE Insulated PVC Sheathed Cable
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 12_GTP - 4CX 300 SQMM.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               12. Annexure 12_GTP - 4CX 300 SQMM
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 13 GTP for 1.1 kV 4-Core 16 sqmm XLPE Insulated PVC Sheathed Cable - Controlled Copy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               13. Annexure 13 GTP for 1.1 kV 4-Core 16 sqmm XLPE Insulated PVC Sheathed Cable - Controlled Copy
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 14_GTP - 4Cx35 Sqmm.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               14. Annexure 14_GTP - 4Cx35 Sqmm
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 15 GTP for 1.1 kV 4-Core 95 sqmm XLPE Insulated PVC Sheathed Cable - Controlled Copy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               15. Annexure 15 GTP for 1.1 kV 4-Core 95 sqmm XLPE Insulated PVC Sheathed Cable - Controlled Copy
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 16_GTP - 4Cx150 Sqmm.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               16. Annexure 16_GTP - 4Cx150 Sqmm
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 17_GTP - Tortek - 1C 4 sq.mm Flxi cable - 03.07.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               17. Annexure 17_GTP - Tortek - 1C 4 sq.mm Flxi cable - 03.07.2024
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 18_11kV 3 CORE 150 SQ.MM. XLPE INSULATED THERMOPLASTIC SHEATHED CABLE.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               18. Annexure 18_11kV 3 CORE 150 SQ.MM. XLPE INSULATED THERMOPLASTIC SHEATHED CABLE
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 19_11kV 300 SQMM XLPE INSULATED THERMOPLASTIC SHEATHED CABLE.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               19. Annexure 19_11kV 300 SQMM XLPE INSULATED THERMOPLASTIC SHEATHED CABLE
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 20_11kV 3C 70SQMM XLPE INSULATED CABLE_Controlled Copy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               20. Annexure 20_11kV 3C 70SQMM XLPE INSULATED CABLE_Controlled Copy
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 21_GTP for 19-33kv 3-core 400 sq.mm. XLPE insulated thermoplastic sheathed cables - Rev_A - Controlled Copy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               21. Annexure 21_GTP for 19-33kv 3-core 400 sq.mm. XLPE insulated thermoplastic sheathed cables - Rev_A - Controlled Copy
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 22_LT Cable 2Cx6mm - 190324.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               22. Annexure 22_LT Cable 2Cx6mm - 190324
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 23_Transformer Invoices.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               23. Annexure 23_Transformer Invoices
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 24 Break Up of Transformer Cost.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               24. Annexure 24 Break Up of Transformer Cost
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 25_UPPCL Letter dt. 18.08.2008.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               25. Annexure 25_UPPCL Letter dt. 18.08.2008
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 26_UPERC Order dt. 26.05.2009.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               26. Annexure 26_UPERC Order dt. 26.05.2009
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure-27_NPCL Request for Refund of SD Rs. 11.28 Cr.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              27. Annexure-27_NPCL Request for Refund of SD Rs. 11.28 Cr
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 28_Honourable UPERC Letter to PVVNL.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               28. Annexure 28_Honourable UPERC Letter to PVVNL
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 29_UPPCL Demand of Rs. 210 Cr.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               29. Annexure 29_UPPCL Demand of Rs. 210 Cr
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 30_UPPCL Letter 18.03.2023 and 24.03.2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               30. Annexure 30_UPPCL Letter 18.03.2023 and 24.03.2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 31_NPCL Letter 25.03.2023 and 24.05.2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               31. Annexure 31_NPCL Letter 25.03.2023 and 24.05.2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 32_Review Petition 1704 and Commission Order.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               32. Annexure 32_Review Petition 1704 and Commission Order
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 33_Relevant Pages of Tariff Order dated 26.08.2021.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               33. Annexure 33_Relevant Pages of Tariff Order dated 26.08.2021
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-6/Annexure 34_Relevant Pages of the Appeal 465 of 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               34. Annexure 34_Relevant Pages of the Appeal 465 of 2023
                              </a>
                            </li>
                          </ul>
                          
        </div>
        </div>
        </div>
        
) : null}

{show9 ? (
  <div className="row justify-content-center">
  <div className="col-sm-12" >
    <div className="row">
    <ul className="tariff_standard">
    <li>
                              <img src="assets/img/doc-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-7/NPCL reply to 7th Deficiency Note Final.docx"
                                target="_blank" rel="noreferrer"
                              >
                               1. NPCL reply to 7th Deficiency Note Final
                              </a>
                            </li>
    <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-7/Annexure-1 F10A FY 2023-24.xlsb"
                                target="_blank" rel="noreferrer"
                              >
                               2. Annexure-1 F10A FY 2023-24
                              </a>
                            </li>                           
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-7/Annexure-2 F10A FY 2024-25.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               3. Annexure-2 F10A FY 2024-25
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-7/Annexure-3 NPCL Vehicle Policy 2018.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               4. Annexure-3 NPCL Vehicle Policy 2018
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-7/Annexure-4 NPCL Vehicle Policy 2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               5. Annexure-4 NPCL Vehicle Policy 2023
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL Tariff Petition for ARR 2024-25/Reply to Deficiency Note-7/Annexure-5 Assets Retirement Details FY 2022-23.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                               6. Annexure-5 Assets Retirement Details FY 2022-23
                              </a>
                            </li>
                            
                          </ul>
        </div>
        </div>
        </div>
        
) : null}
            </div>
            </section>
        </div>
            </>
          )
      }

      export default TariffPetetionARR2425